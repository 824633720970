import { BLOG } from '../types';

const initialState = {}; // cannot use immutable Map for initial state unless you use redux-immutable

export default (state = initialState, action) => {
  switch (action.type) {
    case BLOG:
      return action.payload;
    default:
      return state;
  }
};
