import theme from '/style/theme';
import { singleOverflow, transition, placeholder } from '/style/mixin';

const MobiScroll = `
/* mobiscroll */
.mbsc-fr-lock.mbsc-fr-lock-ios {
  overflow-y: scroll;
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  height: auto;
}
body {
  .mbsc-mobiscroll {
    .mbsc-sc-whl-w {
      max-height: 300px;
      overflow-y: auto;
      width: 100%;
    }
    .mbsc-sc-lbl, .mbsc-fr-hdr, .mbsc-fr-btn {
      color: #4eccc4;
    }
    .mbsc-sc-whl-l {
      border-color: #4eccc4;
    }
    .mbsc-fr-btn.mbsc-active, .mbsc-mobiscroll.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover,
    &.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover, .mbsc-mobiscroll .mbsc-sc-itm:focus {
      background: transparent;
    }
    &.mbsc-fr-bottom {
      .option-item {
        text-align: left;
      }
    }
    .option-item {
      padding: 0;
      font-size: 14px;
      line-height: 1.64;
      color: ${theme.mid_gy};
      .activity_name {
        line-height: 1.64;
        max-width: 100%;
        font-size: 14px;
        margin: 0;
        color: #666;
        font-weight: ${theme.normal};
      }
      .activity_price {
        text-align: left;
        font-size: 12px;
        line-height: 1.64;
        margin: 0
        color: ${theme.gy};
        font-weight: ${theme.normal};
        .prtb_price_selling {
          font-size: 14px;
          font-weight: ${theme.bold};
          color: ${theme.mid_gy};
          line-height: 1.64;
        }
        .prtb_price_original {
          text-decoration: line-through;
        }
      }
    }
    &.mbsc-fr-bubble {
      @media ${theme.sm} {
        &.mbsc-fr {
          z-index: 50;
        }
        .mbsc-sc-whl-w {
          max-height: 300px;
          overflow-y: auto;
        }
        .mbsc-sc-whl {
          height: auto!important;
          margin: 0;
        }
        .mbsc-sc-whl-c {
          top: 0;
          margin-top: 0!important;
          height: auto!important;
        }
        &.mbsc-popup {
          .mbsc-sc-whl-c {
            border-top: 0px;
          }
          .mbsc-fr-w {
            margin-top: 0px;
          }
        }
        .mbsc-sc-whl-sc {
          // wheel
          transform: none!important;
          margin-top: 0!important;
        }
        .mbsc-sc-itm-ml {
        }
        .mbsc-sc-itm {
            line-height: 0!important;
            padding: 12px 15px!important;
            display: none;
            &.mbsc-btn-e {
              display: block;
              height: auto!important;
            }
        }
      }
    }
    &.mbsc-orange {
      .mbsc-fr-btn {
        color: ${theme.brand};
      }
      .mbsc-sc-whl-l {
        border-color: ${theme.brand};
      }
      &.mbsc-popup{
        z-index: 250;
      }
    }
    &.mbsc-mint {
      .mbsc-fr-btn {
        color: ${theme.trazy_mint};
      }
      .mbsc-sc-whl-l {
        border-color: ${theme.trazy_mint};
      }
    }
    &.mbsc-calendar {
      .mbsc-fr-btn {
        color: ${theme.brand};
        font-size: 18px;
      }
    }
    .mbsc-cal-days>div {
      border-bottom : 0;
      color: ${theme.mid_gy};
      font-size: 14px;
      line-height: 1;
      margin: 27px 0 20px;
      font-weight: ${theme.medi};
      &.mbsc-cal-week-day6 {
        color: ${theme.trazy_mint};
      }
      &.mbsc-cal-week-day0 {
        color: ${theme.brand};
      }
    }
    .mbsc-cal {
      .mbsc-cal-btn {
        position: absolute;
        width: 42px;
        height: 42px;
        line-height: 42px;
        margin-top: -21px;
        top: 50%;
        color: #fff;
        &.mbsc-cal-next {
          right: 32px;
        }
        &.mbsc-cal-prev {
          left: 32px;
        }
      }
    }
    .mbsc-cal-c {
      padding: 0;
      .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
        background-color: ${theme.brand};
      }
      .mbsc-cal-hdr {
        background-color: ${theme.light_og};
        color: #fff;
        .mbsc-cal-btn-w {
          position: relative;
          height: 93px;
          line-height: 93px;
          font-size: 20px;
          font-weight: ${theme.medi};
        }
      }
    }
    .mbsc-fr-w {
      text-align: center;
      background-color: #fff;
      width: auto!important;
      min-width: 0;
    }
    &.mbsc-fr {
      @media ${theme.sm} {
        z-index: 250;
      }
    }
    &.mbsc-fr-pointer .mbsc-sel-one .mbsc-sc-itm {
      /* mobiscroll option item */
      border-top: 1px solid ${theme.line_gy};
      ${transition()}
      &:first-child {
        border-top: 0;
      }
      &:hover {
        background-color: ${theme.light_gy1};
      }
    }
    &.mbsc-fr-pointer .mbsc-sel-one.mbsc-sc-whl-multi .mbsc-sc-itm-sel {
      background-color: ${theme.light_og1};
      color: ${theme.trazy_og};
    }
    .mbsc-cal-day-date {
      margin: 0;
      height: 36px;
      width: 36px;
      line-height: 34px;
      font-size: 14px;
    }
  }
  .mbsc-select-input[type="text"] {
    ${singleOverflow}
    cursor: pointer;
    padding-right: 30px;
    outline: 0;
    background-repeat: no-repeat;
    background-position: 100% center;
    background-image: url('/images/ui/select-chevron.svg');
    color: ${theme.brand};
    ${transition()};
    &.mint {
      color: ${theme.trazy_mint};
    }
    &.timepicker {
      background-image: url('/images/ui/ico-timepicker.svg');
      &.timepicker-popup {
        font-size: 14px; 
      }
    }
    &.active {
      ${placeholder(theme.brand)};
      border-color: ${theme.brand};
      background-image: url('/images/ui/select-chevron_active.svg');
      &.timepicker {
        background-image: url('/images/ui/ico-timepicker_active.svg');
      }
    }
    &.selected {
      background-image: url('/images/ui/select-chevron_selected.svg');
      &.timepicker {
        background-image: url('/images/ui/ico-timepicker_active.svg');
      }
    }
    &.default {
      color: ${theme.gy};
      font-size: 14px;
      &.has-value {
        color: ${theme.mid_gy};
      }
    }
    @media ${theme.sm} {
      background-position: 98% center;
    }
    &.timepicker-popup {
      background-position: 97% center;
    }
  }
  .mbsc-error {
    display: inline-block;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 6px;
      right: 38px;
      height: 38px;
      width: 38px;
      background-image: url(${require('/public/images/ui/ico-exclamation.svg')});
    }
    .mbsc-select-input[type="text"] {
      border-color: ${theme.brand};
      background-repeat: no-repeat;
      background-position: 100% center;
      padding-right: 80px;
    }
  }
  &.mbsc-popup {
    .mbsc-fr-popup {
      margin-top: 0px;
    }
  }
}
`;

export default MobiScroll;
