import { CURRENCY, CURRENCYINFO } from '../types';
import { setCookie } from '/utils/cookie';

const initialState = {
  tr_currency: 'USD',
  tr_currency_source: 'auto',
  data: [],
};

export default (state = initialState, action) => {
  // console.log(state);
  switch (action.type) {
    case CURRENCY:
      setCookie('tr_currency', action.payload.tr_currency);
      setCookie('tr_currency_source', action.payload.tr_currency_source);
      return Object.assign({}, state, {
        tr_currency: action.payload.tr_currency,
        tr_currency_source: action.payload.tr_currency_source,
      });
    case CURRENCYINFO:
      // define on window to use data from 'conrverPrice' util
      // if (window) window.currency_info = action.payload;
      return Object.assign({}, state, {
        data: action.payload,
      });
    default:
      return state;
  }
};
