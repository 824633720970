const defaultTitle = "Book Best Things to Do in Asia - Your Travel Shop for Asia";
const defaultDescription = '';
export default {
  title: defaultTitle,
  titleTemplate: "%s",
  description: defaultDescription,
  openGraph: {
    type: 'website',
    title: defaultTitle,
    description: defaultDescription,
    url: 'https://www.trazy.com',
    site_name: 'Trazy', 
    defaultImageWidth: 630,
    defaultImageHeight: 1200,
    images: [
      {
        url: 'https://trazy-assets.s3.amazonaws.com/images/main/opengraph/trazy-main.jpg',
        width: 1200,
        height: 630,
        alt: 'Trazy - www.trazy.com',
      },
      
    ]
  },
  facebook: {
    appId: 135840233162006,
  },
  twitter: {
    handle: '@trazykorea',
    site: 'https://www.trazy.com',
    cardType: 'summary_large_image',
  },
  additionalMetaTags:[
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0, maximum-scale=5.0, minimum-scale=1.0'
    }
  ]
};
