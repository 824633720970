import theme from './theme';
import { imgResponsive, transition, singleOverflow } from './mixin';

const Table = `

/* 테이블 */
.table-responsive {
  overflow-x: auto;
}
table {
  margin: 0;
  caption {
    text-align: left;
  }
  th,
  td {
    padding: 15px;
    font-size: 15px;
    line-height: 1.53;
    color: ${theme.mid_gy};
    font-weight: ${theme.normal};
    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
    /* price table extra style */
    .prtb_subtitle {
      font-weight: ${theme.bold};
    }
    .prtb_custom_price_wrapper {
      display: table;
    }
    .prtb_custom_price_row {
      display: table-row;
    }
    .prtb_custom_price_val,
    .prtb_custom_price_num {
      padding: 5px 0;
      display: table-cell;
    }
    .prtb_custom_price_val {
      padding-left: 5px;
      white-space: nowrap;
    }
    .prtb_custom_price_num {
      padding-right: 3px;
    }
    .prtb_participant_type {
      display: inline-block;
      white-space: nowrap;
      margin-left: 0;
    }
    .prtb_price {
      white-space: nowrap;
      display: inline-block;
      margin-left: 10px;
      margin-right: 7px;
    }
    .prtb_price_original {
      text-decoration: line-through;
      line-height: 23px;
      color: #999;
      font-size: 13px;
      margin: 0;
    }
    .included_col_header {
      width: 38px;
      height: 38px;
      display: block;
      background-image: url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/mobile/icon_included_table%402x.png);
      background-size: cover;
      margin: 7px 5px;
    }
    .footable-toggle {
      display: block;
      width: 38px;
      height: 38px;
      padding: 0;
      margin: 23px auto 7px;
      background-image: url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/mobile/arrow-img-multi-01%402x.png);
      background-repeat: no-repeat;
      background-size: 152px 152px;
      background-position: -38px -76px;
      cursor: pointer;
      position: relative;
      &:before {
        content: "Check";
        display: block;
        text-decoration: underline;
        font-weight: ${theme.bold};
        color: ${theme.trazy_og};
        position: absolute;
        top: -12px;
        left: -2px;
      }
    }
    &.included_col_mobile {
      width: 88px;
      padding: 0;
      display: table-cell;
      @media ${theme.sm} {
        display: none!important;
      }
    }
    &.included_col_desktop {
      // display: none;
      @media ${theme.sm} {
        display: table-cell!important;
        pointer-events: none!important;
      }
    }
  }
  tr{
    &:nth-child(1) td {
      border-top: 1px solid ${theme.very_light_gy};
    }
    &.footable-detail-show {
      td {
        .footable-toggle {
          background-position: 0 -76px;
        }
      }
    }
    &.footable-row-detail {
      @media ${theme.sm} {
        display: none!important;
      }
      td {
        background-color: #fff;
      }
      .footable-row-detail-name {
        font-weight: ${theme.bold};
        color: ${theme.dark_mint};
      }
    }
  }
  td {
    border-right: 1px solid ${theme.very_light_gy};
    border-bottom: 1px solid ${theme.very_light_gy};
    &:last-child {
      border-right: 0;
    }
  }
  th {
    background-color: ${theme.light_gy1};
    text-align: center;
    color: #666;
    font-size: 15px;
    font-weight: 600;
    border-bottom: 1px solid ${theme.very_light_gy};
    border-top: 1px solid ${theme.very_light_gy};
    border-right: 1px solid ${theme.very_light_gy};
  }
  &.options-table, 
  &.itin-table, 
  &.itin-table2, 
  &.gen-price-table {
    tr:nth-child(1) td {
      // text-align: center;
      border-top: 0;
    }
  }
  &.itin-table {
    /* experience detail page itinerary style */
    width: 100%;
    border-top:0;
    td {
      vertical-align: top;
      position: relative;
      border-bottom: none;
    }
    tr {
      td {
        padding-top: 20px;
      }
      td:first-child {
        color: ${theme.dark_mint};
        font-weight: ${theme.bold};
        padding: 20px 15px 0 0;
        width: 100px;
        border-right: 10px solid ${theme.light_mint2};
        &:before {
          content: '';
          position: absolute;
          z-index: 2;
          right: -11px
          top: 31px;
          margin-top: -6px;
          display: block;
          width: 13px;
          height: 13px;
          border-radius: 50px;
          background-color: ${theme.trazy_mint};
        }
      }
      &.no-bullet {
        td:first-child {
          &:before,
          &:after {
            display: none;
          }
          border-right: 0;
        }
      }
      &.first-row {
        // th {
        //   padding: 0;
        //   border-bottom: 0;
        //   background-color:transparent;
        //   font-size: 15px;
        //   line-height: 1.6;
        //   text-align: left;
        //   color: ${theme.mid_gy};
        //   b {
        //     font-weight: ${theme.bold};
        //   }
        // }
        td:first-child {
          &:after {
            content: '';
            height: 26px;
            width: 10px;
            background-color: #fff;
            display: block;
            width: 10px;
            right: -10px;
            position: absolute;
            top: 0;
          }
        }
      }
      &.last-row {
        td:first-child{
          border-right: 0;
          &:before {
            right: -6px;
          }
          &:after {
            content: '';
            height: 36px;
            width: 10px;
            background-color: ${theme.light_mint2};
            display: block;
            position: absolute;
            top: 0;
            right: -5px;
          }
        }
      }
    }
  }
  &.itin-table2 td {
    border-left: none!important;
    border-right: none!important;
    min-width: 100px;
    padding: 10px 5px;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    word-break: normal;
    border-color: #ccc;
    color: #666;
    text-align: center;
    vertical-align: top;
    &:nth-child(1) {
      text-align: center;
      vertical-align: middle;
    }
    &:nth-child(2) {
      text-align: left;
      vertical-align: middle;
    }
  }
  /* price table custom */
  &.footable-details,
  &.footable {
    position: relative;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
  }
  &.has-included {
    .prtb_participant_type {
      display: block;
    }
    .prtb_price {
      display: inline-block;
      margin-left: 0;
      margin-right: 7px;
    }
    .prtb_participant {
      margin-top: 20px; 
      &:first-child {
        margin-top: 0;
      }
    }
    tr.footable-detail-show td.included_col_mobile {
      border-bottom: 1px solid #fff;
    }
  }
  &.has-row-span {
    // if has fake tr in td
    .rowspan-col-wrapper {
      padding: 0;
      .rowspan-col {
        padding: 14px 15px;
        border-top: 1px solid ${theme.very_light_gy};
        &:first-child {
          border-top: 0;
        }
      }
    }
  }
  &.options-table {
    th {
      background-color: ${theme.light_mint};
      padding: 15px;
      text-align: center;
      color: #0097a7;
      font-size: 15px;
      font-weight: 600;
      border-bottom: 1px solid ${theme.trazy_mint};
      border-top: 1px solid ${theme.trazy_mint};
      border-right: 1px solid ${theme.trazy_mint};
      &:nth-last-child(1) {
        border-right: 0;
      }
    }
    tr:nth-child(odd) {
      background-color: ${theme.light_gy1};
    }
    td {
      border-right: 1px solid ${theme.very_light_gy};
      border-bottom: 1px solid ${theme.very_light_gy};
      &:last-child {
        border-right: 0;
      }
    }
  }
  &.gen-price-table {
    th {
      color: ${theme.dark_mint};
      background-color: ${theme.light_mint};
      font-weight: ${theme.bold};
      text-align: center;
      border-top: 1px solid ${theme.trazy_mint};
      border-bottom: 1px solid ${theme.trazy_mint};
      border-right: 1px solid ${theme.trazy_mint};
      &.included_col_desktop {
        border-right: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
    td {
      border-bottom: 1px solid ${theme.very_light_gy};
      border-right: 1px solid ${theme.very_light_gy};
      &:last-child,
      &.included_col_desktop {
        border-right: 0;
      }
    }
    tr {
      &.tr-bg {
        background-color: ${theme.light_gy1}
      }
    }
    &.has-row-span {
      .rowspan-col {
        &.tr-bg {
          background-color: ${theme.light_gy1}
        }
      }
    }
  }
  &.gen-price-table2{
    .prtb_subtitle {
      display: block;
      margin-bottom: 15px;
    }
  }
  &.gen-price-table12 {
    td {
      text-align: center;
    }
  }
}
.gen-price-table-wrapper {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  @media ${theme.sm_max} {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      width: 30px;
      height: 100%;
      border-radius: 10px 0 0 10px / 50% 0 0 50%;
      box-shadow: -5px 0 10px rgba(0,0,0,.25);
    }
  }
  table.gen-price-table {
    .prtb_subtitle {
      white-space: nowrap;
    }
  }
}
.gen-price-table-inner {
  overflow-x: auto;
  overflow-y: hidden;
}
`;
export default Table;
