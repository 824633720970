import theme from './theme';
import tableStyle from './table';
import datepickerStyle from './datepicker';
import lightgalleryStyle from './lightgallery';
import daterangepickerStyle from './daterangepicker';
import mobiscrollStyle from './mobiscroll';
import slickStyle from './slick';
import photoswipeStyle from './photoswipe';
import editorStyle from './editor';
import { imgResponsive, transition } from './mixin';

const Base = `
/* 타이포그래피 */
.text-h1,
h1 {
  font-size: 32px;
  font-weight: ${theme.bold};
  line-height: 1.24;
  color: ${theme.dark};
  @media ${theme.sm} {
    font-size:4px;
  }
}
.text-h2,
h2 {
  font-size: 28px;
  font-weight: ${theme.normal};
  line-height: 1.29;
  color: ${theme.dark};
  @media ${theme.sm} {
    font-size: 32px;
  }
}
.text-h3,
h3 {
  font-size: 24px;
  font-weight: ${theme.medi};
  line-height: 1.29;
  color: ${theme.dark};
  @media ${theme.sm} {
    font-size: 28px;
  }
}
.text-h4,
h4 {
  font-size: 20px;
  font-weight: ${theme.medi};
  line-height: 1.5;
  color: ${theme.dark};
  @media ${theme.sm} {
    font-size: 24px;
  }
}
.text-h5,
h5 {
  font-size: 18px;
  font-weight: ${theme.medi};
  line-height: 1.6;
  color: ${theme.dark};
  @media ${theme.sm} {
    font-size: 20px;
  }
}
.text-h6,
h6 {
  font-size: 17px;
  font-weight: ${theme.medi};
  line-height: 1.76;
  color: ${theme.dark};
}
.text-h1,
h1,
.text-h2,
h2,
.text-h3,
h3,
.text-h4,
h4,
.text-h5,
h5 {
  word-break: keep-all;
  margin-top: 40px;
  margin-bottom: 20px;
}
.text-h6,
h6 {
  margin-top: 40px;
  margin-bottom: 10px;
  + p {
    margin-top: 0;
  }
}
.text-h5 + .box {
  margin-top: 10px;
}
p {
  ${theme.p_style}
  margin: 20px 0;
}
/* 이미지 */
img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0;
}
img.aligncenter {
  margin: 0 auto;
}
.img-caption {
  text-align: center;
  font-size: 13px;
  color: #999;
}
figure {
  margin: 20px 0;
  padding: 0;
  img {
    ${imgResponsive}
  }
}
/* 구분선 */
hr {
  margin: 30px 0;
  height: 0;
  border: none;
  border-bottom: 1px solid #f0f0f0;
}
/* 리스트 - 기본 */
ul {
  margin: 20px 0;
  list-style: none;
  > li {
    position: relative;
    margin: 0;
    padding-left: 13px;
    font-size: 14px;
    font-weight: ${theme.normal};
    text-align: left;
    vertical-align: top;
    line-height: 1.64;
    letter-spacing: -0.2px;
    color: ${theme.mid_gy};
    &:first-child {
      margin-top: 0;
    }
    &:before {
      content: "";
      position: absolute;
      top: 10px;
      left: 0;
      width: 3px;
      height: 3px;
      border-radius: 100px;
      background-color: ${theme.dark};
      display: inline-block;
    }
  }
}
ol {
  margin: 20px 0;
  padding-left: 0;
  list-style-position: inside;
  > li {
    position: relative;
    font-size: 14px;
    line-height: 1.64;
    margin-top: 4px;
  }
}
.no-style {
  margin: 0;
  > li {
    padding-left: 0;
    &:before {
      content: none;
      background-color: transparent;
    }
  }
}
dl {
  margin: 0;
  list-style: none;
  > dt {
    position: relative;
    margin-bottom: 2px;
    font-size: 14px;
    line-height: 1.64;
    color: inherit;
    font-weight: ${theme.bold};
  }
  > dd {
    margin-left: 0;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 1.64;
  }
}
/* 박스 */
blockquote {
  margin: 20px 0;
  padding-left: 20px;
  border-left: solid 4px ${theme.line_gy};
}
.box {
  display: block;
  margin: 20px 0;
  padding: 20px;
  color: ${theme.dark};
  border: solid 1px ${theme.line_gy};
  border-radius: 5px;
  background-color: #fff;
  &.box-gray {
    border: solid 1px ${theme.very_light_gy};
    background-color: ${theme.line_gy};
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.btn-group {
  @include clearfix();
  text-align: center;
  margin-top:10px;
  margin-bottom:10px;
  .btn {
    margin:1px;
  }
}
/* 비디오 */
.video-container {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  // @media ${theme.lg} {
  //   width: auto;
  //   height: auto;
  //   padding-bottom: 0;
  //   iframe {
  //     position: static;
  //   }
  // }
}
/* 첫 번째 요소 상단 여백 삭제 */
.text-h1,
h1,
.text-h2,
h2,
.text-h3,
h3,
.text-h4,
h4,
.text-h5,
h5,
.text-h6,
h6,
ul,
ol,
p,
table,
.box,
blockquote {
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="number"],
input[type="time"] {
  outline: 0;
  font-size: 16px;
  box-shadow: none;
  border-radius: 0;
  -webkit-appearance: none;
  width: 100%;
  height: 50px;
  line-height: 20px;
  padding: 15px 16px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${theme.line_gy};
  color: ${theme.mid_gy};
  ${transition('border-color .15s ease-out')}
  @media ${theme.sm} {
    font-size: 14px;
  }
}

/* trazy style legacy */
.mobile_view, .tablet_view {
  display: none!important;
  @media ${theme.xs_max} {
    font-weight: ${theme.bold};
    color: #0097a7;
    display: block!important;
  }
}
/* responsive helper class */
.show-md {
  display: none;
  @media ${theme.md} {
    display: block;
  }
}
.hide-md {
  display: block;
  @media ${theme.md} {
    display: none;
  }
}
.show-sm {
  display: none;
  @media ${theme.sm} {
    display: block;
  }
}
.show-sm-inline-block {
  display: none;
  @media ${theme.sm} {
    display: inline-block;
  }
}
.show-md-inline-block {
  display: none;
  @media ${theme.md} {
    display: inline-block;
  }
}
.hide-sm {
  display: block;
  @media ${theme.sm} {
    display: none;
  }
}
.hide-sm-inline-block {
  display: inline-block;
  @media ${theme.sm} {
    display: none;
  }
}
/* photo swipe */
.pswp__caption__center {
  text-align: center;
}
/* react map gl */
.mapboxgl-popup-content {
  max-width: 280px;
  padding: 15px 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  .title {
    font-size: 15px;
    margin-right: 28px;
    font-weight: ${theme.bold};
    line-height: 1.67;
    letter-spacing: -0.2px;
    color: ${theme.dark_gy};
  }
  .address {
    font-size: 12px;
    line-height: 1.75;
    letter-spacing: -0.2px;
    color: ${theme.dark_gy};
  }
  .mapboxgl-popup-close-button {
    display: block;
    padding: 0;
    width: 39px;
    height: 39px;
    text-indent: -9999px;
    background-position: center center;
    background-image: url('/images/ui/mapbox-popup-close.svg');
    &:hover {
      background-color: #fff;
    }
  }
}
/*sweealert*/
.sweetalert-confirm {
  padding: 10px 32px;
  background-color: ${theme.brand};
  color: #fff;
  border-radius: 5px;
  box-shadow: none!important;
  ${transition()}
  &:hover {
    background-color: ${theme.brand_hover};
    color: #fff;
  }
}
.sweetalert-cancel {
  background-color: ${theme.light_gy3};
  padding: 10px 32px;
  color: ${theme.body_bg};
  border-radius: 5px;
  ${transition()}
}
.sweet-alert {
  margin-left: inherit!important;
  margin-right: inherit!important;
  .description {
    margin-top: 25px;
    padding: 0 30px;
    color: ${theme.gy};
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
  }
}
.custom-sweet-alert{
  .close-popup-btn {
    background-image: url(https://d2ifd8cfqxdvq1.cloudfront.net/images/email/v3/btn_close_sgy.png);
    width: 38px;
    height: 38px;
    top: 0px;
    right: 0px;
    position: absolute;
    cursor: pointer;
    background-size: cover;
    @media ${theme.xs_max} {
      background-image: url(https://d2ifd8cfqxdvq1.cloudfront.net/images/email/v3/btn_close_sgy@2x.png);
    }
  }
  p {
    display: block !important;
    vertical-align: top;
    margin: 0px auto !important;
    padding: 0 30px 30px 30px;
    .sweetalert-confirm{
      width: calc(50% - 5px);
      float:right;
      height: 50px;
      font-weight: ${theme.medi};
      margin-right: 0px !important;
      line-height: 50px;
      padding: 0px;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1) !important;
      position:relative;
    }
    .sweetalert-cancel{
      width: calc(50% - 5px);
      float:left;
      margin-right:10px !important;
      height: 50px;
      font-weight: ${theme.medi};
      line-height: 50px;
      padding: 0px;
    }
  }
}
.confirm-cancel-popup{
  h2{
    display: none;
  }
  .top-content{
    width:100%;
    height: 150px;
    background-image:url("https://d2ifd8cfqxdvq1.cloudfront.net/images/email/v3/img_confirm.png");
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    position:relative;
    background-size:cover;
    background-position:center;
    @media ${theme.xs_max} {
      // this image is 404
      // background-image:url("https://d2ifd8cfqxdvq1.cloudfront.net/images/email/v3/img_confirm@2x.png");
    }
  }
  .middle-content {
    padding: 30px;
    font-size: 16px;
    color: ${theme.dark_gy};
    font-weight: ${theme.bold};
    text-align: left;
  }
  .cancel-policy-content {
    color:${theme.dark_gy};
    border-top: 1px solid ${theme.very_light_gy};
    border-bottom: 1px solid ${theme.very_light_gy};
    padding: 30px 0px;
    margin: 0px 30px 22px 30px;
    text-align: left;
    font-size: 14px;
    line-height: 23px;
  }
  .bottom-content {
    text-align: left;
    padding: 0px 30px;
    margin: 3px;
    font-size: 14px;
    line-height: 23px;
    .options {
      display: inline-block;
      padding: 8px 0 7px 0;
      margin-bottom: 3px;
      width: 100%;
      vertical-align: middle;
      input[type="checkbox"] {
        display: none;
        &:checked {
          + label {
            &:before{
              background-color:${theme.check_og}
              border-color:${theme.trazy_og};
            }
          }
        }
      }
      label {
        display: inline-block;
        cursor: pointer;
        position: relative;
        padding-left: 23px;
        font-size: 15px;
        font-weight: ${theme.normal};
        vertical-align: top;
        line-height: 23px;
        margin-bottom: 0px;
        color: ${theme.trazy_og};
        &:before {
          content: "";
          display: inline-block;
          width: 13px;
          height: 13px;
          border-radius: 3px;
          position: absolute;
          left: 0;
          background-color: ${theme.body_bg};
          border: 1px solid ${theme.trazy_og};
          margin-top: 5px;
        }
      }
    }
  }
  p {
    .sweetalert-confirm{
      &:before{
        content:"";
        position:absolute;
        top:0px;
        left:0px;
        bottom:0px;
        right:0px;
        display:block;
        background-color:rgba(255,255,255,0.5);
      }
      &.clickable{
        &:before{
          display:none;
        }
      }
    }
  }
  &.warning{
    .options {
      background-color:${theme.light_og1};
      border-radius:5px;
      position:relative;
      &:after{
        content:"";
        display:block;
        position:absolute;
        top:8px;
        right:0px;
        width:38px;
        height:38px;
        background-image:url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/img_og_exclam_all.png);
        background-size:76px 38px;
        background-position:-38px 0px;
        @media ${theme.xs_max} {
          background-image:url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/img_og_exclam_all@2x.png);
        }
      }
      label{
        max-width:calc(100% - 38px);
      }
    }
  }
}
.provider-confirm-popup, .provider-confirmed-popup{
  h2 {
    display:none
  }
  .top-content{
    width: 354px;
    height: 221px;
    background-image:url(https://d2ifd8cfqxdvq1.cloudfront.net/images/email/v3/img_confirmation.png);
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    position:relative;
    background-size:cover;
    @media ${theme.xs_max} {
      background-image:url(https://d2ifd8cfqxdvq1.cloudfront.net/images/email/v3/img_confirmation@2x.png);
    }
  }
}
.provider-confirm-popup{
  .bottom-content{
    padding:30px 0px;
    font-size: 16px;
    color: #464646;
    font-weight:600;
    text-align:center;
  }
}
.provider-confirmed-popup{
  .bottom-content{
    padding:30px;
    font-size: 15px;
    line-height:23px;
    color:#666666;
    text-align:left;
    font-weight:400
    .thank-text{
      font-size: 20px;
      letter-spacing: -0.3px;
      color: #464646;
      margin-bottom:20px;
      font-weight:600;
    }
  }
  p {
    .sweetalert-confirm{
      width:134px;
    }
  }
}
.provider-confirm-info-popup{
  h2{
    display:none;
  }
  .top-content{
    background-color: #f3f3f3;
    padding:30px 40px;
    font-size: 16px;
    font-weight:600;
    color: #464646;
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    text-align:left;
    position:relative;
    border-bottom:1px solid #e3e3e3;
  }
  .bottom-content{
    padding:40px 30px 30px 30px;
    text-align:left;
    textarea{
      height: 143px;
      border-radius:5px;
      border:1px solid #eaeaea;
      padding:15px;
      font-size:14px;
      color:#666666;
      width: 100%;
    }
    input{
      width: 100%;
    }
    label{
      font-size: 14px;
      color: #666666;
      font-weight:600;
      margin-bottom:10px;
      display:block;
    }
    .additional-info{
      margin-bottom:30px;
      .error-message{
        line-height:38px;
        font-size:14px;
        letter-spacing: -0.2px;
        color: #f05634;
        &:before{
          content:"";
          height:38px;
          width:38px;
          background-image:url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/img_og_exclam_all.png);
          background-position:-38px 0;
          display:inline-block;
          vertical-align:top;
        }
      }
    }
    .additional-file-input, .submit-btn{
      display:none;
    }
    .additional-file-input-dummy{
      width:100%;
      height: 50px;
      border-radius:5px;
      background-color: #ffffff;
      border:1px solid #eaeaea;
      position:relative;
      cursor:pointer;
      line-height:50px;
      font-size: 14px;
      padding-left:15px;
      color: #666666;
      &:after{
        content:"";
        width:38px;
        height:38px;
        background-image:url(https://d2ifd8cfqxdvq1.cloudfront.net/images/email/v3/icon_file_attachment.png);
        background-size:cover;
        position:absolute;
        top:5px;
        right:5px;
      }
    }  
  }
  p {
    .sweetalert-confirm{
      width: auto;
      padding: 0 50px;
    }
  }
}
.delete-confirm-popup {
  .confirm-question{
    margin-bottom: 30px;
  }
}
body.stop-scrolling {
  overflow: visible;
}
/* react-custom-scroll */
.rcs-custom-scroll .rcs-inner-handle {
  background-color: ${theme.light_gy4};
}
/* react-modal */
.react-modal-content-full {
  @media ${theme.xs_max} {
    top: 0!important;
    right: 0!important;
    bottom: 0!important;
    left: 0!important;
    padding: 0!important;
    margin: 0!important;
    transform: none!important;
    background-color: ${theme.light_gy1}!important;
  }
}
/* mobile menu & cart */
.side-opened {
  @media ${theme.xs_max} {
    position: fixed!important;
  }
}
/* google translation */
body {
  top: 0!important;
  .goog-te-banner-frame {
    display: none;
  }
  &
}
.translated-ltr {
  body {
    top: 40px!important;
    .goog-te-banner-frame {
      display: block;
    }
  }
}
// ie reset
select::-ms-expand {
  display: none;
}
// overview style
.detail-wrapper {
  img {
    display: inline;
    vertical-align: middle;
  }
}
${editorStyle}
${tableStyle}
${datepickerStyle}
${lightgalleryStyle}
${daterangepickerStyle}
${slickStyle}
${mobiscrollStyle}
${photoswipeStyle}
`;

export default Base;
