import { RECENTLY } from '../types';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case RECENTLY:
      if (window.safeStorage && window.safeStorage.getItem('recently')) {
        state = JSON.parse(window.safeStorage.getItem('recently'));
      }
      if (state.some(item => item.custom_url === action.payload.custom_url)) {
        // check if new data is included in existing data
        return state;
      }
      state.push(action.payload);
      window.safeStorage.setItem('recently', JSON.stringify(state.slice(-4)));
      return state.slice(-4);
    default:
      return state.slice(-4);
  }
};
