import theme from '/style/theme';
import { transition, resetList } from '/style/mixin';

const slickStyle = `
/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    * {
      outline: 0;
    }
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
    &.slick-active
    {
      z-index: 1;
    }
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
/* trazy customize */
.slick-dots {
  ${resetList}
  margin-top: 20px;
  font-size: 0;
  text-align: center;
  > li {
    display: inline-block;
    height: 10px;
    margin: 0 5px;
    vertical-align: top;
    line-height: 1;
    button {
      display: block;
      margin: 0;
      padding: 0;
      width: 10px;
      height: 10px;
      border-radius: 100px;
      text-indent: -9999px;
      background-color: #e3e3e3;
      cursor: pointer;
      line-height: 1;
      ${transition()}
    }
    &.slick-active {
      button {
        background-color: ${theme.brand};
      }
    }
  }
}
.slick-arrow {
  // display: none!important;
  position: absolute;
  z-index: 1;
  bottom: 198px;
  width: 27px;
  height: 44px;
  padding: 0;
  text-indent: -9999px;
  background-position: center center;
  background-repeat: no-repeat;
  // @media ${theme.lg} {
    // display: block!important;
  // }
  &.slick-prev {
    left: 30px;
    background-image: url(${require('../public/images/ui/carousel-prev.svg')});
  }
  &.slick-next {
    right: 30px;
    background-image: url(${require('../public/images/ui/carousel-next.svg')});
  }
}
.slick-play-pause {
  padding: 0;
  margin: 0;
}
`;

export default slickStyle;
