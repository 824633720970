import { MERGECART, REMOVECART, SETCART } from '../types';

const initialState = {}; // cannot use immutable Map for initial state unless you use redux-immutable

export default (state = initialState, action) => {
  switch (action.type) {
    case MERGECART:
      return action.payload;
    case REMOVECART:
      return action.payload;
    case SETCART:
      return action.payload;
    default:
      return state;
  }
};
