import theme from './theme';

export const imgResponsive = (props = 'block') => `
     display: ${props};
     max-width: 100%;
     height: auto;
`;
export const placeholder = (props = theme.light_gy3) => `
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${props};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${props};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${props};
  }
`;
export const srOnly = `
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  clip: rect(0,0,0,0);
`;

export const clearfix = `
     &:before,
     &:after {
          content: " "; // 1
          display: table; // 2
     }
     &:after {
          clear: both;
     }
`;

//transition
export const transition = (props = '.15s all ease-out') => `
  transition: ${props};
`;

// reset list
export const resetList = `
  margin: 0;
  padding: 0;
  ul {
    margin: 0;
  }
  li {
    padding: 0;
    margin: 0;
    &:before {
      display: none;
    }
  }
`;

export const singleOverflow = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
`;

export const backgroundCover = `
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const textOverflow = (lines, lineHeight) => {
  const maxhValue = lines * lineHeight;
  return `max-height:  ${maxhValue}em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    word-break: break-word;`;
};
