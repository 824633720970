
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
// @ts-nocheck
import App from 'next/app';
import Router from 'next/router';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import theme from '/style/theme';
import ImportFont from '/style/font';
import Normalize from '/style/normalize';
import BaseStyle from '/style/base';
import { Provider } from 'react-redux';
import withRedux from 'next-redux-wrapper';
import mobiscroll from '@mobiscroll/react';
import store from '/store';
import NProgress from 'nprogress';
import { DefaultSeo } from 'next-seo';
import SEO from '/next-seo.config';
import runRedirectRules from '/utils/runRedirectRules';
import actions from '../store/actions';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-custom-scroll/dist/customScroll.css';
import 'react-circular-progressbar/dist/styles.css';
import 'rc-slider/assets/index.css';
import 'nprogress/nprogress.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'lightgallery/src/css/lightgallery.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import isCrawler from '/utils/isCrawler';
import axios from 'axios';
import getConfig from 'next/config';
import getUrlParameter from '/utils/getUrlParameter';
import { getCookie, getDecryptedCookie, setCookie } from '/utils/cookie';
import * as Sentry from '@sentry/node';
import * as analytics from '/utils/analytics';
import { fromJS } from 'immutable';

const { publicRuntimeConfig } = getConfig();

Sentry.init({
  dsn: 'https://9f2ac5f0a00646d68ad4efd38ae29536@o16346.ingest.sentry.io/5248655',
  enabled: process.env.NODE_ENV !== 'development',
});

mobiscroll.settings = {
  theme: 'mobiscroll',
};

const GlobalStyle = createGlobalStyle`
  ${ImportFont}
  ${Normalize}
  ${BaseStyle}
`;

class TrazyApp extends App {
  static async getInitialProps({ Component, ctx, err, router }) {
    ctx.store.dispatch(
      actions.setServer({
        asPath: ctx.asPath,
        is_crawler: isCrawler(ctx),
      }),
    );
    const { locale } = router;
    runRedirectRules(ctx, locale);
    const pageContext = { ...ctx, locale };
    const pageProps = Component.getInitialProps ? await Component.getInitialProps(pageContext) : {};

    return { pageProps, err };
  }
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
    super.componentDidCatch(error, errorInfo);
  }
  componentDidMount() {
    const { store, router } = this.props;
    const { device, authentication, subdomain } = store.getState();
    const { locale } = router;
    // set device
    if (getUrlParameter('src') === 'app' && device === 'web') {
      setCookie('tr_src', 'app', 365); // save app cookie for one year
      store.dispatch(actions.setDevice('app'));
    }
    // update header cart counter
    store.dispatch(actions.getCart(authentication.token, locale));
    // get header
    axios
      .get(`${publicRuntimeConfig.API_PATH}/home/header?locale=${locale}`)
      .then(res => {
        if (res.data && res.data.country_list) {
          store.dispatch(actions.setCities(res.data.country_list));
        }
      })
      .catch(err => console.log(err));
    // get footer
    axios
      .get(`${publicRuntimeConfig.API_PATH}/home/footer`)
      .then(res => {
        store.dispatch(actions.setBlog(res.data.blog_list));
      })
      .catch(err => console.log(err));

    // retrieve if logged in user has no data
    if (subdomain === 'business') {
      if (!getDecryptedCookie('user_business') && authentication.token) {
        store.dispatch(
          actions.getUser({
            token: authentication.token,
          }),
        );
      }
    } else {
      if (!getDecryptedCookie('user') && authentication.token) {
        store.dispatch(
          actions.getUser({
            token: authentication.token,
          }),
        );
      }
    }

    const url = window.location.href;
    if (url.indexOf('/experience/detail/') < 0 && url.indexOf('/spot/') < 0 && url.indexOf('/theme/') < 0) {
      setTimeout(() => analytics.pageview(authentication), 100);
    }

    // router events
    Router.events.on('routeChangeStart', destination => {
      // business redirect logic
      const { authentication } = store.getState(); //  retrieve auth again
      const authorized_pages = [
        '/profile/settings',
        '/mybooking',
        '/wishlist',
        '/mypoint',
        '/things-to-do',
        '/search',
        '/experience',
        '/spot',
        '/theme',
        '/event',
        '/campaign',
        '/activity-theme',
      ];
      let is_authorized_page = authorized_pages.some(v => destination.startsWith(v));
      if (subdomain === 'business' && !authentication.token && is_authorized_page) {
        Router.events.emit('routeChangeError');
        Router.push(`/?redirect=${destination}`);
        throw 'No authentication. Please login';
      }
      NProgress.start();
    });
    Router.events.on('routeChangeError', () => NProgress.done());
    Router.events.on('routeChangeComplete', () => {
      NProgress.done();
      window.scroll(0, 0);
      if (url.indexOf('/experience/detail/') < 0 && url.indexOf('/spot/') < 0 && url.indexOf('/theme/') < 0) {
        setTimeout(() => analytics.pageview(authentication), 100);
      }

      const { language } = store.getState();
      const { locale: store_locale } = language;
      const { locale: cur_locale } = router;
      if (store_locale != cur_locale) {
        axios
        .get(`${publicRuntimeConfig.API_PATH}/home/header?locale=${cur_locale}`)
        .then(res => {
          if (res.data && res.data.country_list) {
            store.dispatch(actions.setCities(res.data.country_list));
          }
        })
        .catch(err => console.log(err));
      }
    });

    window.addEventListener('storage', e => {
      if (e.key == 'cart' && e.newValue) {
        store.dispatch(actions.setCart(fromJS(JSON.parse(e.newValue))));
      }
    });
  }

  render() {
    const { Component, pageProps, err, store } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <DefaultSeo {...SEO} />
        <Provider store={store}>
          <GlobalStyle />
          <Component {...pageProps} err={err} />
        </Provider>
      </ThemeProvider>
    );
  }
}

const __Page_Next_Translate__ = withRedux(store, { debug: false })(TrazyApp);


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  