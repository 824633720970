import { combineReducers } from 'redux';
import authReducer from './authReducer';
import authPartnerReducer from './authPartnerReducer';
import authCompanyReducer from './authCompanyReducer';
import currencyReducer from './currencyReducer';
import languageReducer from './languageReducer';
import uiReducer from './uiReducer';
import recentlyReducer from './recentlyReducer';
import translationReducer from './translationReducer';
import reservationReducer from './reservationReducer';
import reservedReducer from './reservedReducer';
import cartReducer from './cartReducer';
import expiredPointReducer from './expiredPointReducer';
import sumPointReducer from './sumPointReducer';
import citiesReducer from './citiesReducer';
import deviceReducer from './deviceReducer';
import serverReducer from './serverReducer';
import blogReducer from './blogReducer';
import dimensionReducer from './dimensionReducer';
import subDomainReducer from './subDomainReducer';

const rootReducer = combineReducers({
  authentication: authReducer,
  authentication_partner: authPartnerReducer,
  authentication_company: authCompanyReducer,
  currency: currencyReducer,
  language: languageReducer,
  uistatus: uiReducer,
  recently: recentlyReducer,
  translation: translationReducer,
  cart: cartReducer,
  reservation: reservationReducer,
  reserved: reservedReducer,
  expired_point: expiredPointReducer,
  sum_point: sumPointReducer,
  cities: citiesReducer,
  device: deviceReducer,
  blog: blogReducer,
  server: serverReducer,
  dimension: dimensionReducer,
  subdomain: subDomainReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
